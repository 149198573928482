import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, Subscription, throwError as observableThrowError} from 'rxjs';
import {isNullOrUndefined} from 'util';
import {AppConstants} from '../../constants/app-constants';
import {Message} from '../classes/message';
import {DataModel} from '../data-models/data-model';
import {MessageService} from './message.service';
import {RequestTimeService} from './request-time.service';
import {SpinnerService} from './spinner.service';
import {TransparentSpinnerService} from './transparent-spinner.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {FormGroup} from '@angular/forms';
import {isEmpty} from 'rxjs/operators';


@Injectable()
export class CommunicatorService implements OnDestroy {
  protected subscriptions: Subscription[] = [];
  protected _SERVICE = AppConstants.SERVICE;

  constructor(public $http: HttpClient,
              public router: Router,
              public spinner: SpinnerService,
              public transparentSpinnerService: TransparentSpinnerService,
              public requestTimeService: RequestTimeService,
              public messageService: MessageService) {
  }

  get SERVICE(): string {
    return this._SERVICE;
  }

  set SERVICE(value: string) {
    this._SERVICE = value;
  }

  public index(endpoint: string, dataModel: DataModel<any>): Observable<any> {
    this.spinner.show();

    return this.$http.get(this.SERVICE + endpoint + '.json' + location.search)
      .map(res => {
        return res;
      })
      .map((response: any) => {
        dataModel.dataItems.next([]);
        if (!!response.data) {
          dataModel.dataItems.next(response.data);
          dataModel.dataItemsFiltered.next(dataModel.dataItems.value);
        }
        this.spinner.hide();
        this.requestTimeService.finished();
        return dataModel.dataItems;
      });
  }

  public view(endpoint: string, dataModel: DataModel<any>, snapshot: string): Observable<any> {
    this.spinner.show();
    return this.$http.get(
      this.SERVICE + endpoint + '/' + snapshot + '.json' + location.search
    )
      .map((response: any) => {
        //dataModel.data.value.emptyObj ();
        //dataModel.data.next(new (dataModel.data.value.constructor)());
        //dataModel.data.value.init(response.data);
        //dataModel.buildFormGroup(dataModel.data.value);

        if (!!response.data) {
          dataModel.data.next(response.data);
          dataModel.buildFormGroup(response.data);
        }
        this.spinner.hide();
        return dataModel.data;
      });
  }

  public edit(endpoint: string, dataModel: DataModel<any>, snapshot: string, formGroup?: FormGroup): Observable<any> {
    console.log(formGroup.value, console.log());

    this.transparentSpinnerService.show();
    return this.$http.put(this.SERVICE + endpoint + '/' + snapshot + '.json', (!!formGroup) && !this.isEmpty(formGroup.value) ? formGroup.value : dataModel.data.value
  )
  .
    map((response: any) => {
      if (!isNullOrUndefined(response.success)) {

        // Todo auslagern in Service Funktion;
        if (response.messages) {
          for (let i = 0; i < response.messages.length; i++) {
            const tmpMessage = new Message();
            tmpMessage.text = response.messages[i];
            tmpMessage.type = 'success';
            this.messageService.addMessage(tmpMessage);
          }
        } else {
          const ms = new Message();
          ms.text = 'Daten gespeichert';
          ms.type = 'success';
          this.messageService.addMessage(ms);
        }

        if (!isNullOrUndefined(response.data)) {
          dataModel.buildFormGroup(response.data);
        }

      }
      this.transparentSpinnerService.hide();
      return response;

    })
      .catch((response: any) => {

        if (!isNullOrUndefined(response) && !isNullOrUndefined(response.error) && !isNullOrUndefined(response.error.data) && !isNullOrUndefined(response.error.data.errors)) {
          const errorObj = response.error.data.errors;
          console.log(errorObj);
          for (const key in errorObj) {
            if (Object(errorObj)
              .hasOwnProperty(key)) {
              const errorValue = errorObj[key];
              for (const keyName in errorValue) {
                if (Object(errorValue)
                  .hasOwnProperty(keyName)) {
                  const err = errorValue[keyName];
                  const tmpMessage = new Message();
                  tmpMessage.text = key + ' ' + keyName + ': ' + err;
                  tmpMessage.type = 'warning';
                  this.messageService.addMessage(tmpMessage);
                }

              }
            }
          }
          return new BehaviorSubject(response.error.data.errors).asObservable();
        }
        return observableThrowError(response);
      });

  }

  public add(endpoint, f, activatedRoute?): Observable<any> {
    return this.$http.post(this.SERVICE + endpoint + '.json', f.value)
      .map((response: any) => {

        const res = response;
        console.log(response);
        if (!isNullOrUndefined(response.success)) {
          if (response.messages) {
            for (let i = 0; i < response.messages.length; i++) {
              const tmpMessage = new Message();
              tmpMessage.text = response.messages[i];
              tmpMessage.type = 'success';
              this.messageService.addMessage(tmpMessage);
            }
          }
          console.log(activatedRoute.url.length);
          if (activatedRoute.url.length === 3) {
            this.router.navigate([`/${activatedRoute.url[0].path}/${activatedRoute.url[1].path}/edit/${res.data.id}`])
              .catch((rejection) => {
                console.log(rejection);
              });
          } else if (activatedRoute.url.length === 2) {
            this.router.navigate([`/${endpoint}/edit/${res.data.id}`])
              .catch((rejection) => {

              });
          }
          // if (confirm('Wollen Sie weitergeleitet werden?')) {

          // }
        }

        return response;
      });
  }

  public delete(endpoint, id, dataModel?): Observable<any> {
    return this.$http.delete(this.SERVICE + endpoint + '/' + id + '.json')
      .map((response) => {
        console.log(response);
        return response;
      })
      .map((response: any) => {
        console.log(response);
        const mes = new Message();
        if (response.success) {
          mes.text = 'Die Daten wurden gelöscht';
          mes.type = 'success';

        }
        this.messageService.addMessage(mes);
        return response;
      });

  }

  private isEmpty(obj) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  ngOnDestroy(): void {
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
  }
}
