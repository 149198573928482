// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  name: 'staging',
  myservice: 'https://staging.my.imexdental.de/',
  dentnetservice: 'https://develop.dent-net.de/api/',
  dentnetsearchtoken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjIsImV4cCI6MTYwNjk5MTA1Nn0.t4QCjccc9d9FL2zrFr3kn8LeJlthJY6b7bYApmng9eM'
};
